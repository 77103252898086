// primary
export var primary_turquoise_500 = "#04D2C5";
export var primary_purple_500 = "#6848FF"; // netural

export var netural_grey_100 = "#FBFCFE";
export var netural_grey_200 = "#F0F3F8";
export var netural_grey_300 = "#e2e8f0";
export var netural_grey_500 = "#b7c0cc";
export var netural_grey_800 = "#404753";
export var netural_grey_900 = "#191E27"; // secondary

export var secondary_red_500 = "#EE4444";
export var secondary_green_500 = "#4CAF50";
export var secondary_blue_100 = "#eff5fb";
export var secondary_blue_500 = "#1f70c7"; // text

export var text_light_major = "#fff";
export var text_dark_major = "#1e2134";
export var text_dark_minor = "#506176";
export var text_dark_accessory = "#a1a8b3";